<template>
  <div class="container">
    <section class="banner"></section>
    <section>
      <div class="checkbox">
        <span>项目分类：</span>
        <el-checkbox-group v-model="product">
          <el-checkbox-button v-for="(item,index) in productOptions"  :label="item.value" :key="index">{{item.label}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="checkbox">
        <span>项目状态：</span>
        <el-checkbox-group v-model="status">
          <el-checkbox-button v-for="(item,index) in statusOptions"  :label="item.value" :key="index">{{item.label}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="checkbox">
        <span>通过状态：</span>
        <el-checkbox-group v-model="complete">
          <el-checkbox-button v-for="(item,index) in completeOptions" :label="item.value" :key="index">{{item.label}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
    </section>
    <section>
      <div class="content">
        <div class="crumbs">
          当前位置： 全部
        </div>
        <div class="search">
          <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="keywords">
          </el-input>
        </div>
      </div>
    </section>
    <section>
      <ul class="list-box">
        <li class="item" v-for="(item,index) in list" :key="index">
          <router-link to="" class="card">
            <div class="top">
              {{ item.taskName }}
            </div>
            <div class="info">
              <div class="time">时间： {{ item.startTime }} - {{ item.endTime }}  </div>
              <div class="bottom">
                <div class="text">
                  <div class="">
                    <i class=""></i> 2人学习
                  </div>&nbsp;&nbsp;&nbsp;
                  <div class="">
                    <i class=""></i> 剩余14天
                  </div>
                </div>
                <div class="progress">
                  <div class="num">{{ item.progress }}%</div>
                  <el-progress type="circle" :percentage="item.progress" color="rgb(26, 183, 0)" width='40' :show-text="false"></el-progress>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="page-Wrap">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000">
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status:[''],
      complete:[''],
      product:[''],
      keywords:'',
      productOptions:[
      {
          value: '',
          label: '全部'
        },
      ],
      statusOptions:[
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '未开始'
        },
        {
          value: '2',
          label: '进行中'
        },
        {
          value: '3',
          label: '已结束'
        }
      ],
      completeOptions:[
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '已完成'
        },
        {
          value: '2',
          label: '未完成'
        },
      ],
      "list":[
            {
                "customerId":2602,
                "taskId":59114,
                "taskName":"新人上路",
                "detailId":0,
                "reexamId":0,
                "startTime":'2023-06-05 00:00 ',
                "endTime":' 2023-07-05 23:59',
                "createTime":1685926570,
                "createUid":0,
                "createUser":"",
                "portrait":"",
                "coverImg":"//data.51-learning.com/default/cover/19.jpg",
                "folderId":0,
                "folderName":"",
                "folderPath":"",
                "departmentId":0,
                "departmentName":"",
                "lecturerUid":0,
                "lecturerName":"",
                "lecturerType":0,
                "address":"",
                "certificateId":0,
                "passLearnScore":0,
                "documentTimes":0,
                "collaborator":"0",
                "comments":0,
                "learnUsers":2,
                "lastTime":1686740693,
                "effective":0,
                "price1":0,
                "price2":0,
                "minPays":0,
                "starScore":0,
                "starUsers":0,
                "favorites":0,
                "users":5,
                "completeTime":0,
                "learnTime":0,
                "examTime":1795,
                "mapTemplate":0,
                "planId":0,
                "stages":0,
                "details":0,
                "taskYear":0,
                "passStages":0,
                "passDetails":2,
                "signupStart":0,
                "signupEnd":0,
                "signupUsers":0,
                "publishTime":0,
                "outSourceID":0,
                "resourceId":0,
                "mineTimes":0,
                "signupScope":0,
                "signupStatus":0,
                "examPass":1,
                "learnPass":100,
                "examNum":0,
                "learnOrder":2,
                "learnCycle":0,
                "syncData":1,
                "isOverdue":1,
                "collaboratorMarking":1,
                "openFace":0,
                "progress":50,
                "complete":2,
                "qualifier":2,
                "taskType":1,
                "dateType":0,
                "isBuy":0,
                "isOpen":0,
                "signup":0,
                "signupAudit":0,
                "publishType":0,
                "secretData":0,
                "examTimes":0,
                "openStageTimeCycle":0
            }
        ],
    };
  },
  methods:{
    handleSizeChange(){

    },
    handleCurrentChange(){

    },
  },



  mounted () {

  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },

  },

  methods: {
    


  }
};
</script>

<style lang="scss" scoped>
.container {
  .banner{
    width: 100%;
    height: 200px;
    background: url('../../assets/image/banner1.png');
  }
  .checkbox{
    display: flex;
    border-bottom: 1px dashed #e2e2e2;
    padding: 0 10px;
    height: 62px;
    background: #fff;
    align-items: center;
    span{
      font-size: 14px;
      color:#999;
    }
    /deep/.el-checkbox-button{
      padding: 0 5px;
        margin: 5px 35px 5px 0;
        
        cursor: pointer;
        transition: all .3s;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border: none;
        box-sizing: border-box;
        height: 24px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: row;
    }
    /deep/.el-checkbox-button__inner{
      border: none;
      font-size: 14px;
      color:#999;
      height: 24px;
      box-sizing: border-box;
      line-height: 24px;
      padding:0 5px;
      border-radius: 4px;
      &:hover{
        color: #266fff;
      }
    }
    /deep/.is-checked .el-checkbox-button__inner{
      background-color: #266fff;
      color: #fff;
    }
   
  }
  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 0 16px;
    box-sizing: border-box;
    .crumbs{
    font-size: 14px;
    color: #999;
    }
    .search{
      width: 220px;
      height: 32px;
      /deep/.el-input__inner{
        border-radius: 16px;
      }
    }
  }
  .list-box{
    border-radius: 6px;
    .item{
      border-radius: 6px;
      .card{
        display: inline-block;
        background-color: #fff;
        transition: all .3s ease;
        border-radius: 6px;
    &:hover{
      transform: translateY(-4px);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.04);
    }
    .top{
      height: 100px;
      padding: 0 28px 0 44px;
      background-color: #69708b;
      color: #fff;
      font-size: 28px;
      line-height: 100px;
      min-height: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      width: 588px;
      box-sizing: border-box;
      border-radius: 6px 6px 0 0 ;
      &::after{
        content: "";
        width: 4px;
        height: 28px;
        background-color: #fff;
        position: absolute;
        left: 28px;
        top: 36px;
      }
    }
    .info{
      padding: 28px;
      box-sizing: border-box;
      background:#fff ;
      border-radius: 0 0 6px 6px  ;
      .time{
        color: #999;
        font-size: 18px;
        line-height: 1.5;
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        .text{
          display: flex;
          justify-content: unset;
          align-items: unset;
          flex-direction: row;
          color: #666;
          font-size: 18px;
          line-height: 1.5;
          margin-top: 36px;
        }
        .progress{
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-direction: row;
          .num{
            font-size: 26px;
            color: #1ab700;
            margin-right: 14px;
          }
        }
      }
    }
  }
    }
  }
}
.page-Wrap{
  padding: 40px 0;
    text-align: center;
}

</style>
